import React from "react";
import '../App.css';

function Footer(props) {
    return (
        <footer className="app-footer">
            <nav>
                <a href="#" onClick={() => props.setShowPage("MAP")}>Map</a>
                <a href="#" onClick={() => props.setShowPage("LIST")}>List</a>
                <a href="#" onClick={() => props.setShowPage("SUBMISSION")}>Submission</a>
                <a href="#" onClick={() => props.setShowPage("WELCOME")}>Welcome</a>
                <a href="#" onClick={() => props.setShowPage("IOS")}>iOS</a>
                <a href="#" onClick={() => props.setShowPage("ANDROID")}>Android</a>
                <a href="https://skatecreteordie.com/skatecreteordieprivacypolicy.html">Privacy</a>
            </nav>
            <div className="app-store-buttons">
                <a href="https://apps.apple.com/us/app/skatecreteordie/id1099516729" className="app-store-button">
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                        alt="Download on the App Store"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.jaemzware.skate.crete.or.die&hl=en&gl=US" className="play-store-button">
                    <img
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        alt="Get it on Google Play"
                    />
                </a>
            </div>
        </footer>
    );
}

export default Footer;